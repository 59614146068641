<template>
  <div v-if="isOpening" class="centered-content">
    <p>檔案開啟中...</p>
  </div>
  <div v-else class="centered-content">
    <p>本檔案已於外部瀏覽器開啟</p>
    <button @click="goBack" class="green-button">返回 LINE 官方帳號</button>
  </div>
</template>

<script>
import axios from 'axios'
import LiffChecker from "@/utils/LiffChecker";
//import liff from "@line/liff";

export default {
  data() {
    return {
      isOpening: true,
    }
  },
  mounted() {
    this.fetchFile()
  },
  methods: {
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async fetchFile() {
      let { org_code: orgCode, uploadFile: fileId } = this.$route.params
      let url = process.env.VUE_APP_API_BASE_URL + "/" + orgCode + "/liff/upload-file/" + fileId + "/download";
      try {
        const { data } = await axios.get(url)
        console.log(data)
        //this.handleFileUrl(data.url, data.file_name)
        if (data.is_friend) {
          const checker = new LiffChecker(orgCode);
          await checker.initLiff()
          await this.sleep(1000)
          //window.open(data.download_url + '?openExternalBrowser=1')
          window.location.href = data.url
          // this.handleFileUrl(data.url, data.file_name)
          // liff.openWindow({
          //   url: data.download_url,
          //   external: true
          // });
        } else {
          //this.$router.push(data.download_url)
          //window.location.href = data.download_url + '?openExternalBrowser=1'
          window.location.href = data.url
          // window.open(data.download_url + '?openExternalBrowser=1')
          // this.handleFileUrl(data.url, data.file_name)
        }
        this.isOpening = false
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Error",
          text: "檔案已失效",
          icon: "error",
          confirmButtonText: "返回"
        }).then(() => {
          this.$router.go(-1)
        })
      }
    },
    async goBack() {
      const orgCode = this.$route.params.orgCode ?? this.$route.params.org_code
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/general/redirect-to-oa`;
    },
  }
}
</script>


<style scoped>
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.green-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.green-button:hover {
  background-color: darkgreen;
}
</style>
